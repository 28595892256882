import React from 'react'
import { oneOf, string } from 'prop-types'

import imgAppStore from 'images/apple-store-badge.svg'
import imgPlayStore from 'images/google-play-badge.svg'
import { triggerEvent } from 'utils/analytics'

import RichText from 'components/RichText'

import { StoreAppsActions, StoreAppsImg, StoreAppsLinks } from './styles'

const propTypes = {
  linkAppleStore: string,
  linkStoreApps: string,
  template: oneOf(['Template01', 'Template02']),
}

const defaultProps = {
  linkAppleStore: '',
  linkStoreApps: '',
  template: 'Template01',
}

const StoreApps = ({ linkAppleStore, linkStoreApps, template }) => {
  const appleStore =
    linkAppleStore || 'https://apps.apple.com/br/app/creditas/id1270180256'
  const playStore =
    linkStoreApps ||
    'https://play.google.com/store/apps/details?id=br.com.creditas.mobile'
  const title = template === 'Template02' ? '@Work na sua mão!' : 'Baixe o app'
  const description =
    template === 'Template02'
      ? 'Baixe o app e confira os benefícios que você tem acesso. Contrate produtos e serviços com um clique.'
      : 'Conheça o nosso aplicativo e acompanhe tudo sobre o seu empréstimo'

  const handleGAClick = analyticEvent => {
    triggerEvent(analyticEvent)
  }
  return (
    <div>
      <RichText variant="headingXsRegular" color="neutral.90">
        {title}
      </RichText>
      <RichText variant="bodyMdLight">{description}</RichText>
      <StoreAppsActions>
        <StoreAppsLinks
          target="_blank"
          rel="noopener"
          href={appleStore}
          title="Link to Apple Store"
          onClick={() =>
            handleGAClick({
              eventCategory: 'creditas | menu',
              eventAction: 'outbound links | para você | baixe o app',
              eventLabel: `apple store | ${appleStore}`,
            })
          }
        >
          <StoreAppsImg
            src={imgAppStore}
            aspectRatio={3.4}
            alt="Apple Store"
            imgStyle={{ objectFit: 'contain' }}
          />
        </StoreAppsLinks>
        <StoreAppsLinks
          target="_blank"
          rel="noopener"
          href={playStore}
          title="Link to Google Play"
          onClick={() =>
            handleGAClick({
              eventCategory: 'creditas | menu',
              eventAction: 'outbound links | para você | baixe o app',
              eventLabel: `google play | ${playStore}`,
            })
          }
        >
          <StoreAppsImg
            src={imgPlayStore}
            aspectRatio={3.4}
            alt="Google Play"
            imgStyle={{ objectFit: 'contain' }}
          />
        </StoreAppsLinks>
      </StoreAppsActions>
    </div>
  )
}

StoreApps.defaultProps = defaultProps
StoreApps.propTypes = propTypes
StoreApps.displayName = 'StoreApps'

export { StoreApps }
