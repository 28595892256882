import React from 'react'
import { func } from 'prop-types'

import InputField from 'components/FormElements/components/InputField'
import RichText from 'components/RichText'

import { Button, Container, SubTitle } from './styles'

const propTypes = {
  setLoading: func,
  setStep: func,
}

const CheckerForm = ({ setLoading, setStep }) => {
  const [value, setValue] = React.useState('')
  const [error, setError] = React.useState(false)
  const [validationMessage, setValidationMessage] = React.useState('')

  const title = 'Dê o primeiro passo'
  const subTitle = 'Faça parte do nosso universo de benefícios'
  const btnText = 'Continuar'

  const handleSubmit = async () => {
    if (value.length === 0) {
      setValidationMessage('Obrigatório')
      return setError(true)
    }
    if (value.length !== 11) {
      setValidationMessage('Informe um CPF completo')
      return setError(true)
    }

    setLoading(true)

    try {
      const ERROR_UNPROCESSABLE_ENTITY = 422
      const { status } = await fetch(`${process.env.API_CPF}/${value}`)
      setLoading(false)

      if (status === ERROR_UNPROCESSABLE_ENTITY) {
        return setStep(2)
      }

      return setStep(3)
    } catch (err) {
      setLoading(false)

      return setStep(2)
    }
  }

  const handleChange = event => {
    setError(false)
    setValue(event.value)
  }

  return (
    <Container data-testid="checker-form">
      <RichText as="h5" variant="headingSmRegular" color="neutral.90">
        {title}
      </RichText>
      <SubTitle variant="bodyMdLight">{subTitle}</SubTitle>
      <InputField
        label="CPF"
        name="cpf"
        mask="cpf"
        onValueChange={handleChange}
        error={error ? validationMessage : ''}
      />
      <Button
        data-testid="button-checker-form"
        onClick={handleSubmit}
        width="fixed"
        size="large"
      >
        {btnText}
      </Button>
    </Container>
  )
}

CheckerForm.propTypes = propTypes

export default CheckerForm
