import { ButtonPrimaryWithArrow } from '@creditas-ui/button'
import { styled } from '@creditas-ui/system'

import RichText from 'components/RichText'

export const Container = styled.div`
  width: 100%;
`

export const Button = styled(ButtonPrimaryWithArrow)`
  margin-top: 40px;
`

export const SubTitle = styled(RichText)`
  margin: 24px 0;
`
