import { styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

import { LazyImage } from 'components/LazyImage'

export const StoreAppsActions = styled.div`
  display: flex;
  margin-top: 20px;

  ${media.up('4xl')} {
    margin-top: 40px;
  }
`

export const StoreAppsImg = styled(LazyImage)`
  width: 116px;
  height: 36px;
`

export const StoreAppsLinks = styled.a`
  margin-left: -4px;

  + a {
    margin-left: 16px;
  }
`
