import { Spinner as CreditasSpinner } from '@creditas/spinner'
import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

const FormContainer = styled.div`
  align-items: center;
  background-color: ${selectTheme('colors.neutral.0')};
  border-radius: 20px;
  border: 1px solid ${selectTheme('colors.neutral.10')};
  box-shadow: ${selectTheme('shadow.xs')};
  color: ${selectTheme('colors.neutral.80')};
  display: flex;
  justify-content: center;
  margin-bottom: 64px;
  min-height: 305px;
  padding: 24px;
  max-width: 480px;

  ${media.up('4xl')} {
    padding: 48px;
  }
`

const Spinner = styled(CreditasSpinner)``

export { FormContainer, Spinner }
