import React from 'react'
import { object, string } from 'prop-types'

import { HeroFormWithImage } from 'sections/HeroFormWithImage'

import HubspotForm from 'components/HubspotForm/HubspotForm'
import { StoreApps } from 'components/StoreApps'

import CheckerForm from './CheckerForm/CheckerForm'

import { FormContainer, Spinner } from './base.style'

const propTypes = {
  description: string,
  form: object,
  horizontalAlignment: string,
  imagePillDesktop: object,
  imagePillMobile: object,
  title: string,
}

const CpfChecker = ({
  description,
  form,
  horizontalAlignment,
  imagePillDesktop,
  imagePillMobile,
  title,
  ...others
}) => {
  const [loading, setLoading] = React.useState(false)
  const [step, setStep] = React.useState(1)

  return (
    <HeroFormWithImage
      title={title}
      description={description}
      imagePillDesktop={imagePillDesktop}
      imagePillMobile={imagePillMobile}
      horizontalAlignment={horizontalAlignment ? horizontalAlignment : 'right'}
      {...others}
    >
      <FormContainer step={step} data-testid="container">
        {loading && <Spinner data-testid="spinner" complete={!loading} />}
        {!loading && step === 1 && (
          <CheckerForm setLoading={setLoading} setStep={setStep} />
        )}
        {!loading && step === 2 && (
          <HubspotForm
            {...form}
            template="Template02"
            title="Vamos ser parceiros?"
            description="Para ter acesso aos benefícios do @Work sua empresa precisa ser nossa parceira. Insira os dados de onde você trabalha, assim podemos entrar em contato com o seu RH."
          />
        )}
        {!loading && step === 3 && <StoreApps template="Template02" />}
      </FormContainer>
    </HeroFormWithImage>
  )
}

CpfChecker.propTypes = propTypes

export { CpfChecker }
export default CpfChecker
